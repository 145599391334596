body {
  direction: rtl;
  unicode-bidi: embed;
  text-align: right;
}

#hero .swiper-container {
  direction: rtl;
}

#tech-services .tech-services-carousel-container,
#tech-services .latest-articles-carousel-container,
#latest-articles .tech-services-carousel-container,
#latest-articles .latest-articles-carousel-container,
.the-team .tech-services-carousel-container,
.the-team .latest-articles-carousel-container {
  text-align: right;
}
#tech-services .tech-services-carousel-container .card,
#tech-services .latest-articles-carousel-container .card,
#latest-articles .tech-services-carousel-container .card,
#latest-articles .latest-articles-carousel-container .card,
.the-team .tech-services-carousel-container .card,
.the-team .latest-articles-carousel-container .card {
  align-items: flex-start;
}
#tech-services .swiper-button-next,
#latest-articles .swiper-button-next,
.the-team .swiper-button-next {
  left: -15px;
  right: auto;
}
#tech-services .swiper-button-prev,
#latest-articles .swiper-button-prev,
.the-team .swiper-button-prev {
  left: auto;
  right: -15px;
}

.site-main ul {
  padding: 0 15px 0 0;
}

#contact form {
  text-align: right;
}
#contact form input[type=text],
#contact form input[type=email],
#contact form input[type=number] {
  direction: rtl;
  text-align: right;
}

.site-footer .social li {
  margin: 0 0 0 15px;
}

@media screen and (min-width: 992px) {
  #portfolio .portfolio .item {
    text-align: right;
  }
}